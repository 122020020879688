<template>
<div>
<b-container fluid>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-primary card-outline">
                <div class="card-header">
                    <div class="card-title">
                        <h4><i class="fa fa-briefcase"></i>Abos</h4>
                        <p>Liste aller Abos</p>
                        <p v-show="date"><strong>Date:</strong> {{ date }}</p>
                    </div>

                    <div class="clearfix card-tools">
                        <div class="row align-items-center">                            
                            <div class="text-right col-md-12">

                                <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                    <ul class="navbar-nav">  

                                        <li class="nav-item mr-1">
                                            <div class="input-group input-group-sm mt-0">
                                                <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="getResults" :value="search" @input="$store.commit('abos/changeSearch', $event.target.value)" />
                                                <button type="submit" class="btn btn-default btn-sm" @click.prevent="getResults">
                                                    <i class="fas fa-search"></i>
                                                </button>
                                                <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                    <i class="fas fa-times"></i>
                                                </button>
                                            </div>             
                                        </li>

                                        <li class="nav-item">
                                            <router-link class="btn btn-primary btn-sm mr-1" :to="{name: 'abos.create'}" v-if="$auth.check('abos.create')"><i class="fas fa-plus-circle"></i> Neues Abo</router-link>             
                                        </li>

                                        <li class="nav-item">
                                            <button type="button" class="mr-1 btn btn-sm btn-success" @click="getResults" ><i class="fas fa-sync"></i></button>       
                                        </li>

                                        <li class="nav-item">
                                            <button type="button" class="btn btn-sm btn-secondary" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                        </li>
                                        
                                    </ul>
                
                                </nav>

                                <!-- <button type="button" class="mr-1 btn btn-sm btn-secondary" @click="showSupplierOrderModal" :disabled="selectedOrders.length <= 0" >Lieferantenbestellung</button>

                                <div class="mr-1 btn-group" role="group">
                                    <button type="button" class="btn btn-sm btn-warning dropdown-toggle" :disabled="selectedOrders.length <= 0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-boxes"></i> Stapelverarbeitung</button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="#" @click="setStatus('order', 2)">Status: In Bearbeitung</a>
                                        <a class="dropdown-item" href="#" @click="printPicklist">Drucken: Pickliste</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#" @click="setStatusAndPrint('order', 2)">Status & Drucken</a>
                                    </div>
                                </div>


                                <router-link class="btn btn-primary btn-sm" :to="{name: 'orders.create'}" v-if="$auth.check('orders.create')"><i class="fas fa-plus-circle"></i> Neue Bestellung</router-link> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <b-row >
                        <b-col>
                            <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <th>Aktiv</th>

                                    <th>Anbieter</th>
                                    
                                    <th>
                                        <a href="#" @click.prevent="changeSort('abonumber')">Abonummer</a>
                                        <span v-if="this.params.sort_field == 'abonumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'abonumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('next_date')">Nächste Ausführung</a>
                                        <span v-if="this.params.sort_field == 'next_date' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'next_date' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>Pausiert</th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('invoice_amount')">Betrag</a>
                                        <span v-if="this.params.sort_field == 'invoice_amount' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'invoice_amount' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>Zahlungsart</th>
                                    <th>Versandart</th>
                                    <th>Firma</th>
                                    <th>Kunde</th>
                                    <th>E-Mail</th>
                                    <th>Aktion</th>
                                    
                                </thead>
                                <tbody>
                                    <tr v-for="(order) in orders.data" :key="order.id">
                                        <td>
                                            <i v-if="order.active == 1" class="fas fa-check"></i>
                                            <i v-else class="fas fa-times"></i>
                                        </td>
                                        <td>
                                            <span class="badge" :class="setAnbieterBadge(order.type)">{{ order.type.toUpperCase() }}</span>
                                        </td>
                                        
                                        <td>
                                            <span class="badge badge-pill badge-dark">{{ order.abonumber }}</span>
                                            
                                        </td>
                                        <td>
                                            <!-- {{ order.next_date | formatDate }} -->
                                            {{ generateDate(order.start_date, order.next_date) | formatDate }}
                                        </td>
                                        <td>
                                            <div v-for="suspend in order.suspend" :key="suspend">
                                                <span v-if="date < suspend" class="badge badge-pill badge-warning" >{{ suspend }}</span>
                                            </div>
                                        </td>
                                        <td>
                                            {{ order.invoice_amount | toCurrency }}<br>
                                        
                                        <td>{{ order.payment.description }}</td>
                                        <td>{{ order.dispatch.name }}</td>
                                        <td>{{ order.company.name }}</td>
                                        <!-- <td>{{ order.customer.last_name}}, {{ order.customer.first_name}}</td> -->
                                        <td>
                                            <span v-if="order.billing.company != null"><strong>{{ order.billing.company }}</strong><br></span>
                                            <span>{{ order.billing.last_name}}, {{ order.billing.first_name }}</span>
                                        </td>
                                        <td>{{ order.customer.email}}</td>
                        
                                        <td>
                                            <!-- <router-link class="mr-1 btn btn-success btn-sm" :to="{name: 'products.details', params: {id: props.row.product.id}}" v-if="$auth.check('products.show') && props.row.product != null"><i class="fas fa-box"></i></router-link> -->
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'customers.details', params: {id: order.customer_id}}" v-if="$auth.check('customers.show')"><i class="fas fa-fw fa-user"></i></router-link>
                                            <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'abos.show', params: {id: order.id}}" v-if="$auth.check('abos.show') && order.type != 'backend'"><i class="fas fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'abos.details', params: {id: order.id}}" v-if="$auth.check('abos.edit') && order.type == 'backend'"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteOrder(order.id)" variant="danger" v-if="$auth.check('abos.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <div class="clearfix align-middle card-footer">
                    <pagination v-if="notEmptyObject(orders)" class="float-left" :data="orders" @pagination-change-page="getResults" :limit="3"></pagination>
                    
                    <select class="float-right form-control-sm" v-model="params.per_page">
                        <option value="25">25 Artikel</option>
                        <option value="50">50 Artikel</option>
                        <option value="75">75 Artikel</option>
                        <option value="100">100 Artikel</option>
                        <option value="125">125 Artikel</option>
                        <option value="150">150 Artikel</option>
                    </select>
                    <p v-if="notEmptyObject(orders)" class="float-right mr-2">Anzeige Eintrag {{ orders.meta.from }} - {{ orders.meta.to }} von {{ orders.meta.total }}</p>
                </div>
            </div>
        </div>
    </div>
</b-container>
<aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Shop:</label>
                        <select v-model="params.company_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Firma --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Zahlungsart:</label>
                        <select v-model="params.payment_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Zahlungsart --</option>
                            <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Versandart:</label>
                        <select v-model="params.dispatch_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Versandart --</option>
                            <option v-for="dispatch in dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.name }}</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>
</div>
</template>

<script>
import {mapState} from 'vuex';
import moment from 'moment'

export default {
    title: '',
    name: 'AboIndex',

    data() {
        return {
            orders: [],
            companies: [],
            dispatches: [],
            payments: [],
            // params: {
            //     sort_field: 'abonumber',
            //     sort_direction: 'desc',
            //     payment_id: '',
            //     dispatch_id: '',
            //     company_id: '',
            //     per_page: 25,
            // },
            // search: '', 
            date: moment().format('YYYY-MM-DD'),  
        }
    },

    watch: {
        params: {
            handler () {
                this.getResults();
            },
            deep: true
        },
    },

    computed:{
        ...mapState('abos', ['params', 'search', 'page'])
    },


    methods:{ 
        // currentDate() {
        //     const current = new Date();
        //     const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
        //     return date;
        // }, 

        generateDate(start, next){
            const date1 = new Date(start);
            const date2 = new Date(next);

            if(date1 < new Date())
            {
                return date2;
            }
            else{
                return date1;
            }
        },  

        setAnbieterBadge(state){

            if(state == 'backend')
            {
                return 'badge-light'
            }

            if(state == 'stripe')
            {
                return 'badge-success'
            }

            if(state == 'paypal')
            {
                return 'badge-info bg-purple'
            }

            return 'badge-primary';
        },

        resetSearch(){
            this.$store.commit('abos/changeSearch', '');
            this.getResults();
        },

        resetFilter(){
            this.params.payment_id = '';
            this.params.dispatch_id = '';
            this.params.company_id = '';
            this.params.per_page = 25;
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        deleteOrder(id){
            this.$swal({
                title: "Möchtest du das Abo wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/abos/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Abo erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.getResults();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },


        getPayments(){
            this.$Progress.start();
            this.axios
                .get("/payments")
                .then((response) => {
                    this.payments = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getDispatches(){
            this.$Progress.start();
            this.axios
                .get("/dispatches")
                .then((response) => {
                    this.dispatches = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getCompanies(){
            this.$Progress.start();
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        getResults(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/abos", {
                    params: {
                        page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.orders = response.data;
                    this.$store.commit('abos/setPage', page);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.getResults();
        this.getPayments();
        this.getDispatches();
        this.getCompanies();
    }
}
</script>

<style>
.ck-editor__editable {
    min-height: 500px;
    max-height: 500px;
}
</style>